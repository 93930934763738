<div class="main-container">
  <div class="tw-p-4 tw-bg-primary-epsilon">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-mb-5">
      <div>
        <h1
          class="tw-text-primary-alpha tw-font-bold tw-text-xl md:tw-text-2xl">{{'KoneCall'}}</h1>
        <h6 class="tw-text-primary-eta tw-font-semibold tw-text-sm md:tw-text-base">Order History</h6>
      </div>

      <!-- Search Component -->
      <div class="tw-mt-5 md:tw-mt-0 tw-w-full md:tw-w-72">
        <div class="tw-mb-3">
          <div class="tw-relative tw-mb-4 tw-flex tw-w-full tw-flex-wrap tw-items-stretch">
            <input
              type="search"
              [(ngModel)]="searchTerm"
              (keyup)="filterOrders(searchTerm)"
              class="tw-relative tw-m-0 tw-block tw-h-12 t-ww-[1px] tw-min-w-0 tw-flex-auto tw-rounded-xl tw-border tw-border-solid border-neutral-300 tw-bg-transparent tw-bg-clip-padding tw-px-3 py-[0.25rem] tw-text-base tw-font-normal leading-[1.6] text-neutral-700 tw-outline-none tw-transition tw-duration-200 tw-ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:tw-outline-none"
              placeholder="Search Orders"
              aria-label="Search"
              aria-describedby="button-addon2"/>

            <!--Search icon-->
            <span
              class="tw-ml-[-50px] tw-flex tw-items-center tw-whitespace-nowrap tw-px-3 tw-py-1.5 tw-text-center tw-text-base tw-font-normal text-neutral-700"
              id="basic-addon2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="tw-h-5 tw-w-5 tw-text-primary-beta">
        <path
          fill-rule="evenodd"
          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
          clip-rule="evenodd"/>
      </svg>
    </span>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <div>
        <h6 class="tw-text-primary-eta tw-font-semibold tw-text-sm md:tw-text-base">Choose a shop</h6>
      <div ngbDropdown class="d-inline-block">
        <button ngbDropdownToggle placeholder="Select Shop" id="dropdownHoverButton"
                class="tw-bg-orange-200 tw-mt-4 tw-cursor-pointer tw-w-full hover:tw-bg-orange-300 tw-text-primary-alpha
               tw-font-semibold tw-text-sm tw-rounded-3xl tw-px-4 md:tw-px-12 tw-py-2 md:tw-py-4
                tw-transition tw-duration-250 tw-ease-in-out tw-transform tw-hover:-translate-y-1 tw-hover:scale-110">
          {{selectedShop ? selectedShop.name : 'Select Shop'}}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownHoverButton"
             class="tw-z-10 tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded-lg tw-shadow tw-w-44 tw-border-none">
          <ul class="tw-py-2 tw-text-sm tw-text-gray-700">
            <li *ngFor="let shop of shops">
              <a (click)="selectShop(shop)" ngbDropdownItem
                 class="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer">{{ shop.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

      <div>
        <button class="tw-bg-green-200 tw-mt-4 tw-cursor-pointer tw-w-full hover:tw-bg-green-300 tw-text-primary-alpha
        tw-font-semibold tw-text-sm tw-rounded-3xl tw-px-4 md:tw-px-12 tw-py-2 md:tw-py-4
         tw-transition tw-duration-250 tw-ease-in-out tw-transform tw-hover:-translate-y-1 tw-hover:scale-110" (click)="downloadOrders()">Export to CSV</button>
      </div>
    </div>
    <div class="tw-relative tw-overflow-x-auto tw-shadow-md tw-rounded-lg tw-mt-12">
      <table class="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
        <thead class="tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50">
        <tr>
          <th scope="col" class="tw-px-6 tw-py-3">Name</th>
          <th scope="col" class="tw-px-6 tw-py-3">Address</th>
          <th scope="col" class="tw-px-6 tw-py-3">Shop</th>
          <th scope="col" class="tw-px-6 tw-py-3">Description</th>
          <th scope="col" class="tw-px-6 tw-py-3">Operator</th>
          <th scope="col" class="tw-px-6 tw-py-3">Payment Method</th>
          <th scope="col" class="tw-px-6 tw-py-3">Customer Phone Number</th>
          <th scope="col" class="tw-px-6 tw-py-3">Created At</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let order of filteredOrders">
          <!-- Main Order Row -->
          <tr (click)="toggleDetails(order.id);" class="tw-bg-white tw-border-b tw-cursor-pointer">
            <th scope="row"
                class="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap">{{ '#' + order.id }}</th>
            <td class="tw-px-6 tw-py-4">{{ order?.address }}</td>
            <td class="tw-px-6 tw-py-4">{{ order?.shop?.name }}</td>
            <td class="tw-px-6 tw-py-4">{{ order?.description }}</td>
            <td class="tw-px-6 tw-py-4">{{ order?.user?.name }}</td>
            <td class="tw-px-6 tw-py-4">{{ order?.payment_method }}</td>
            <td class="tw-px-6 tw-py-4">{{ order?.customer_number }}</td>
            <td class="tw-px-6 tw-py-4">{{ order?.created_at | date: 'MM-dd-yyyy HH:mm' }}</td>

            <!-- Toggle icon -->
            <td class="tw-px-6 tw-py-4">
              <a
                class="tw-font-medium tw-text-primary-beta tw-hover:underline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="tw-w-5 tw-h-5 tw-text-primary-beta">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                </svg>
              </a>
            </td>

          </tr>

          <!-- Detail Row for the Order -->
          <tr *ngIf="expandedOrder === order.id" class="tw-bg-gray-100">
            <td colspan="5" class="tw-px-6 tw-py-4">
              <!-- Display fetched order details here -->
              <table class="tw-w-full tw-mb-4">
                <thead>
                <tr class="tw-text-xs tw-text-gray-700">
                  <th scope="col" class="tw-px-3 tw-py-2">Category Name</th>
                  <th scope="col" class="tw-px-3 tw-py-2">Product Name</th>
                  <th scope="col" class="tw-px-3 tw-py-2">Price</th>
                  <th scope="col" class="tw-px-3 tw-py-2">Description</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let detail of orderDetails.get(order.id)">
                  <tr>
                    <td class="tw-px-3 tw-py-2">{{ detail.product.category.name }}</td>
                    <td class="tw-px-3 tw-py-2">{{ detail.product.name }}</td>
                    <td class="tw-px-3 tw-py-2">{{ detail.product.price }}</td>
                    <td class="tw-px-3 tw-py-2">{{ detail.description }}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </td>
          </tr>

        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
