<div class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-bg-gray-100">
  <div class="tw-px-8 tw-py-6 tw-mt-4 tw-text-left tw-rounded-lg tw-bg-white tw-shadow-lg">
    <h3 class="tw-text-2xl tw-font-bold tw-text-center">Login to your account</h3>
    <form (ngSubmit)="login()">
      <div class="tw-mt-4">
        <label class="tw-block" for="email">Email</label>
        <input type="email" placeholder="Email"
               [(ngModel)]="email"
               name="email"
               class="tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-border tw-rounded-md focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-orange-400"
               required>
      </div>
      <div class="tw-mt-4">
        <label class="tw-block">Password</label>
        <input type="password" placeholder="Password"
               [(ngModel)]="password"
               name="password"
               class="tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-border tw-rounded-md focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-orange-400"
               required>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-mt-4">
        <button type="submit"
                class="tw-bg-[#F3F3F3] tw-w-full hover:tw-bg-primary-beta hover:tw-text-white tw-text-primary-alpha tw-font-semibold tw-text-sm tw-rounded-3xl tw-px-8 md:tw-px-16 tw-py-2 md:tw-py-4 tw-transition tw-duration-250 tw-ease-in-out tw-transform tw-hover:-translate-y-1 tw-hover:scale-110">
          {{ 'Login' }}
        </button>
      </div>
    </form>
  </div>
</div>
