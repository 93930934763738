import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private apiUrl = environment.apiEndpoint;
  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router) {
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.apiUrl}/user/login`, {email, password})
      .pipe(
        tap(tokens => this.storeTokens(tokens)),
        catchError(this.handleError)
      );
  }

  private storeTokens(tokens) {
    localStorage.setItem('access_token', tokens.token);
    this.loggedIn.next(true);
    // Handle refresh token if implemented
  }

  private hasToken(): boolean {
    const token = localStorage.getItem('access_token');
    return !!token && !this.jwtHelper.isTokenExpired(token);
  }

  getTokenPayload() {
    const token = localStorage.getItem('access_token');
    return token ? this.jwtHelper.decodeToken(token) : null;
  }

  isAdmin() {
    const payload = this.getTokenPayload();
    return payload && payload.role.toLowerCase() === 'admin';
  }

  getUserRole(): string | null {
    const payload = this.getTokenPayload();
    return payload ? payload.role : null;
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.clear();
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
    // Clear other auth-related storage or perform additional cleanup
  }

  private handleError(error: HttpErrorResponse) {
    // Here, you can handle specific HTTP errors as needed
    return throwError(error.error.message || 'Server Error');
  }
}
