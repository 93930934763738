import {Component, HostListener} from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  isSidebarVisible = false;
  isLargeScreen = window.innerWidth > 640; // or whatever breakpoint you choose

  toggleSidebar(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isLargeScreen = event.target.innerWidth > 640;
  }

  constructor() {
  }

  orders = [
    {itemName: 'Item 1'},
    {itemName: 'Item 2'},
    {itemName: 'Item 3'}
    // ... more orders
  ];
}
