import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  apiUrl = environment.apiEndpoint;
  userSubject: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/user`);
  }

  createUser(userData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/register`, userData);
  }


  updateUser(userId: any, userData: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/user/${userId}`, userData);
  }

  deleteUser(userId: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/user/${userId}`);
  }

  getShops(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/shop`);
  }

  getCategories(shopId): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/categories/shop/${shopId}`);
  }

  getProducts(categoryId): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/products/category/${categoryId}`);
  }

  getIngredients(shopId, productId): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiUrl}/ingredients/get-by-shop`, {
      shop_id: shopId,
      product_id: productId
    });
  }

  createShop(shopData: any): Observable<any> {
    // Replace with your API endpoint and HTTP method
    return this.http.post(`${this.apiUrl}/shop`, shopData);
  }

  updateShop(shopData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/shop/${shopData.id}`, shopData);
  }

  createCategory(shopId, categoryData: FormData): Observable<any> {
    // Append shopId to the FormData
    categoryData.append('shop_id', shopId);

    // Make sure to send FormData directly without spreading into an object
    return this.http.post(`${this.apiUrl}/categories`, categoryData);
  }


  updateCategory(categoryData: FormData): Observable<any> {
    console.log('categoryData', categoryData);
    // Extract the id from the FormData and remove it from the FormData object
    const categoryId = categoryData.get('id');
    categoryData.delete('id');
  
    return this.http.post(`${this.apiUrl}/categories/${categoryId}`, categoryData);
  }

  createProduct(categoryId, productData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/products`, {
      ...productData,
      category_id: categoryId
    });
  }

  updateProduct(productData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/products/${productData.id}`, {
      ...productData,
    });
  }

  createIngredient(shopId, categoryId, productId, ingredientData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/ingredients`, {
      ...ingredientData,
      shop_id: shopId,
      product_id: productId,
    });
  }

  updateIngredient(ingredientData: any): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/ingredients/${ingredientData.id}`, {
      ...ingredientData,
    });
  }

  editShop(shopId, shopData): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/shop`, {
      ...shopData,
    });
  }


  deleteShop(shopId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiUrl}/shop/${shopId}`);
  }

  deleteCategory(categoryId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiUrl}/categories/${categoryId}`);
  }

  deleteProduct(productId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiUrl}/products/${productId}`);
  }

  deleteIngredient(ingredientId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiUrl}/ingredients/${ingredientId}`);
  }
}
