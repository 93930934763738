import {Component} from '@angular/core';
import {AuthenticationService} from './core/services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rln-frontend';

  constructor(private authService: AuthenticationService, private router: Router) {
    // this.authService.isLoggedIn.subscribe(loggedIn => {
    //   if (loggedIn) {
    //     // User is logged in
    //   } else {
    //     // User is not logged in
    //   }
    // });
  }
}
