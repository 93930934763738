import {Directive, Input, TemplateRef, ViewContainerRef, OnInit} from '@angular/core';
import {AuthenticationService} from '../core/services/authentication.service';

@Directive({
  selector: '[appRoleBasedShow]'
})
export class RoleBasedShowDirective implements OnInit {
  private hasView = false;

  @Input('appRoleBasedShow') set roles(allowedRoles: string[]) {
    this.updateView(allowedRoles);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
  }

  private updateView(allowedRoles: string[]) {
    const userRole = this.authService.getUserRole();
    console.log('userRole', userRole);
    const shouldShow = userRole && allowedRoles.map(r => r.toLowerCase()).includes(userRole.toLowerCase());

    if (shouldShow && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!shouldShow && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
