<aside
  class="tw-fixed tw-top-0 tw-left-0 tw-z-40 tw-h-screen tw-transition-transform tw--translate-x-full sm:tw-translate-x-0 tw-bg-white"
  aria-label="Sidebar">
  <div class="tw-h-full tw-px-3 tw-py-16 tw-overflow-y-auto">
    <ul class="tw-flex tw-flex-col tw-items-center tw-h-full tw-font-medium">
      <li *ngFor="let item of sidebarItems">
        <div *appRoleBasedShow="item.role" class="tw-flex tw-items-center tw-p-5 tw-cursor-pointer"
             (click)="navigateTo(item.route)">
          <fa-icon class="tw-text-2xl" [icon]="item['iconName']"
                   [ngClass]="{'active': item.route === currentRoute, 'inactive': item.route !== currentRoute}"></fa-icon>
        </div>
      </li>
      <li class="tw-absolute tw-bottom-16">
      <span (click)="logout()" class="tw-cursor-pointer">
        <fa-icon class="tw-text-2xl" [icon]="faSignOut"></fa-icon>
      </span>
      </li>
    </ul>

  </div>
</aside>
