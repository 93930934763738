import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { Order } from '../models/order.model';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { GooglePlacesService } from '../google-places.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  selectedQuantity = 0;
  private apiUrl = environment.apiEndpoint;
  subtotal = 0;
  total = 0;
  orders: any[] = [];
  ordersChanged = new Subject<any>();
  taxRate = 0;
  showDeliveryMessage = false;
  delivery = { message: '', success: false};
  distance;
  duration;
  constructor(private http: HttpClient, private toastrService: ToastrService, private googlePlacesService: GooglePlacesService) {
  }

  saveOrdersToLocalStorage() {
    localStorage.setItem('orders', JSON.stringify(this.orders));
  }
  downloadOrders(shopId?) {
    return this.http.post(
      `${this.apiUrl}/order-details/download-orders`,
      {
        shop_id: shopId ? shopId : '',
      },
      { responseType: 'blob' }
    );
  }
  loadOrdersFromLocalStorage() {
    const storedOrders = localStorage.getItem('orders');
    if (storedOrders) {
      this.orders = JSON.parse(storedOrders);
      this.ordersChanged.next(this.orders.slice());
      this.calculateTotals();
    }
  }

  addOrder(product: any, quantity: number, ingredients, description) {
    this.orders.push({ ...product, quantity, ingredients, description });
    this.calculateTotals();
    this.ordersChanged.next(this.orders.slice());
    this.saveOrdersToLocalStorage();
  }

  decreaseOrder(orderId: number) {
    const orderIndex = this.orders.findIndex((o) => o.id === orderId);
    if (orderIndex !== -1) {
      // Order exists
      if (this.orders[orderIndex].quantity > 1) {
        // Decrement quantity
        this.orders[orderIndex].quantity--;
      } else {
        // Remove order
        this.orders.splice(orderIndex, 1);
      }
      this.calculateTotals();
      this.ordersChanged.next(this.orders.slice());
      this.saveOrdersToLocalStorage();
    }
  }

  removeOrder(orderToRemove: any): void {
    const index = this.orders.findIndex(
      (order) => order.id === orderToRemove.id
    );
    if (index > -1) {
      this.orders.splice(index, 1);
      this.calculateTotals();
      this.ordersChanged.next(this.orders.slice());
      this.saveOrdersToLocalStorage();
    }
  }

  clearOrders() {
    this.orders = [];
    this.ordersChanged.next(this.orders.slice());
    this.calculateTotals();
    localStorage.removeItem('orders');
  }

  // Logic to calculate totals
  calculateTotals() {
    if (!this.orders || this.orders.length === 0) {
      this.subtotal = 0;
      this.total = 0;
      return;
    }

    this.subtotal = this.orders.reduce((acc, order) => {
      let ingredientCost = 0;
      if (order.ingredients && Array.isArray(order.ingredients)) {
        ingredientCost = order.ingredients.reduce(
          (ingredientAcc, ingredient) => {
            if (ingredient && ingredient.price) {
              return ingredientAcc + parseFloat(ingredient.price);
            }
            return ingredientAcc;
          },
          0
        );
      }

      let orderCost = (parseFloat(order.price) || 0) + ingredientCost;
      return acc + orderCost * (order.quantity || 1);
    }, 0);

    // Calculate total with sales tax
    const taxAmount = this.subtotal * (this.taxRate / 100);
    this.total = this.subtotal + taxAmount;
  }

  setOrders(orders: any[]) {
    this.orders = orders;
    this.ordersChanged.next(this.orders);
    console.log('order items: ', this.orders);
    this.calculateTotals();
    this.saveOrdersToLocalStorage();
  }

  getOrders() {
    return [...this.orders];
  }

  // getOrders(): Observable<Order[]> {
  //   return this.http.get<Order[]>(`${this.apiUrl}/orders`);
  // }

  getOrder(id: number): Observable<Order> {
    return this.http.get<Order>(`${this.apiUrl}/${id}`);
  }

  getAllOrders(shopId?) {
    return this.http
      .post(`${this.apiUrl}/orders/shop`, {
        shop_id: shopId ? shopId : '',
      })
      .pipe(
        map((response: any[]) => {
          return response.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );
        })
      );
  }

  getOrderDetails(id) {
    return this.http.get(`${this.apiUrl}/order-details/${id}`);
  }

  createOrder(description, address, payment_method): any {
    console.log('description ', description);
    console.log('address ', address);
    let shop_id = localStorage.getItem('shop_id');
    return this.http.post<any>(`${this.apiUrl}/orders`, {
      shop_id: shop_id,
      name: localStorage.getItem('shop_name'),
      description,
      payment_method,
      address,
      tax_rate: this.taxRate,
      products: [...this.orders],
      customer_number: localStorage.getItem('customer_number')
        ? localStorage.getItem('customer_number')
        : '',
    });
  }

  updateOrder(id: number, order: Order): Observable<Order> {
    return this.http.patch<Order>(`${this.apiUrl}/${id}`, order);
  }

  deleteOrder(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }


  showSuccess() {
    this.toastrService.success('Order sent successfully!');
  }
  showError() {
    this.toastrService.error('Order not completed, Please try again!');
  }

  calculateDistances(origin, destination, vehicleType) {
    return this.googlePlacesService.calculateDistance(origin, destination)
      .then(response => {
        console.log('Distance:', response);
        return response; // Return the response so it can be used
      }).catch(error => {
        console.error(error);
        throw error; // Ensure errors can be handled
      });
  }
}
