import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {
  faHome,
  faUsers,
  faCog,
  faInfoCircle,
  faChartBar,
  faUserShield,
  faSignOut
} from '@fortawesome/free-solid-svg-icons';
import {AuthenticationService} from '../../core/services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public isSidebarOpen = false;
  currentRoute: string = '';
  faHome = faHome; // Default Icon
  faUser = faUsers; // Default Icon
  faCog = faCog; // Default Icon
  faSignOut = faSignOut; // Default Icon
  faInfoCircle = faInfoCircle; // Default Icon

  sidebarItems = [
    {
      iconName: faHome, // SVG path data
      route: '/home',
      role: ['Admin', 'User']
    },
    {
      iconName: faUsers, // SVG path data
      route: '/user-management',
      role: ['Admin']
    },
    {
      iconName: faChartBar, // SVG path data
      route: '/statistics',
      role: ['Admin']
    },
    {
      iconName: faInfoCircle, // SVG path data
      route: '/order-list',
      role: ['Admin', 'User']
    },
    {
      iconName: faUserShield, // SVG path data
      route: '/admin',
      role: ['Admin']
    },
  ];

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.currentRoute = this.router.url; // Get the current route
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
    this.currentRoute = route;
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  logout(): void {
    this.authenticationService.logout();
  }
}
