<div class="tw-flex tw-flex-col tw-h-screen tw-bg-primary-epsilon">

  <!-- Header -->
  <div class="tw-p-4 tw-bg-white sm:tw-flex sm:tw-justify-between" *ngIf="!isLargeScreen">
   <span (click)="toggleSidebar()" class="tw-block sm:tw-hidden tw-p-4">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="tw-w-6 tw-h-6">
      <path stroke-linecap="round" stroke-linejoin="round"
            d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"/>
    </svg>
  </span>
    <!-- Other header content -->
  </div>

  <!-- Body -->
  <div class="tw-flex tw-flex-1">

    <!-- Sidebar -->
    <div *ngIf="isSidebarVisible || isLargeScreen" class="tw-p-10">
      <app-sidebar></app-sidebar>
    </div>

    <!-- Content -->
    <div class="tw-w-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
