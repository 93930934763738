import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string = '';
  password: string = '';

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  login() {
    this.authService.login(this.email, this.password).subscribe(
      success => {
        if (this.authService.isAdmin()) {
          // Redirect to the admin dashboard if the user is an admin
          this.router.navigate(['/admin']);
        } else {
          // Redirect to the default route for non-admin users
          this.router.navigate(['/']);
        }
      },
      error => {
        console.error(error);
        // Optionally, add more error handling logic here
      }
    );
  }

}
