import { Injectable } from '@angular/core';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {
  private autocompleteService: any;
  private distanceMatrixService: any;
  private loaded = false;
  gmKey = 'AIzaSyAxTr4FnxphOQkH6VTNNB_ArA3pyDao6po'
  private scriptLoadingPromise: Promise<void> | null = null;

  constructor() {

  }

  loadScript(): Promise<void> {
    // If the script is already being loaded, return the existing promise
    if (this.scriptLoadingPromise) {
      return this.scriptLoadingPromise;
    }

    this.scriptLoadingPromise = new Promise((resolve, reject) => {
      // Check if the Google Maps script is already loaded
      if (window['google'] && google.maps && google.maps.places) {
        this.initializeService();
        resolve();
        return;
      }

      // Initialize callback function for the script tag
      window['initMap'] = () => {
        this.initializeService();
        resolve();
      };

      // Create and append the script tag
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.gmKey}&libraries=places&callback=initMap`;
      script.async = true;
      document.head.appendChild(script);

      // Error handling for script loading
      script.onerror = () => {
        reject(new Error('Error loading Google Maps script'));
      };
    });

    return this.scriptLoadingPromise;
  }

  private initializeService() {
    if (window['google'] && google.maps.places) {
      if (!this.autocompleteService) {
        this.autocompleteService = new google.maps.places.AutocompleteService();
      }
      if (!this.distanceMatrixService) { // Initialize the Distance Matrix service here
        this.distanceMatrixService = new google.maps.DistanceMatrixService();
      }
    }
  }


  // getPredictions(query: string): Promise<google.maps.places.QueryAutocompletePrediction[]> {
  //   return new Promise((resolve, reject) => {
  //       if (!this.autocompleteService) {
  //         reject(new Error("Google Maps Places service is not initialized."));
  //         return;
  //       }

  //       this.autocompleteService.getQueryPredictions({ input: query }, (predictions, status) => {
  //         if (status === google.maps.places.PlacesServiceStatus.OK) {
  //           resolve(predictions);
  //         } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
  //           resolve([]);
  //         } else {
  //           reject(new Error(`Failed to get predictions. Status: ${status}`));
  //         }
  //       });
  //   });
  // }

  getPlacePredictions(query: string): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      if (!this.autocompleteService) {
        reject(new Error("Google Maps Places service is not initialized."));
        return;
      }

      this.autocompleteService.getPlacePredictions({
        input: query,
        componentRestrictions: { country: 'USA' }
      }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          resolve([]);
        } else {
          reject(new Error(`Failed to get predictions. Status: ${status}`));
        }
      });
    });
  }
  calculateDistance(origin: string | google.maps.LatLng, destination: string | google.maps.LatLng): Promise<google.maps.DistanceMatrixResponse> {
    return new Promise((resolve, reject) => {
      if (!this.distanceMatrixService) {
        reject(new Error("Distance Matrix Service is not initialized."));
        return;
      }
      this.distanceMatrixService.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          resolve(response);
        } else {
          reject(new Error(`Distance Matrix request failed due to ${status}`));
        }
      });
    });
  }
}
