import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { AdminService } from 'src/app/services/admin.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
  orders: any;
  expandedOrder: number | null = null;
  orderDetails = new Map<number, any>();
  searchTerm: string = '';
  filteredOrders: any;
  title = localStorage.getItem('shop_name');
  shops;
  selectedShop;
  constructor(
    private orderService: OrderService,
    private adminService: AdminService
  ) {}

  toggleDetails(orderId: number): void {
    if (this.expandedOrder === orderId) {
      this.expandedOrder = null;
    } else {
      if (!this.orderDetails.has(orderId)) {
        this.orderService.getOrderDetails(orderId).subscribe((response) => {
          console.log('Setting order details for:', orderId, response);
          this.orderDetails.set(orderId, response);
          this.expandedOrder = orderId;
        });
      } else {
        this.expandedOrder = orderId;
      }
    }
  }
  getShops() {
    this.adminService.getShops().subscribe((data: any) => {
      console.log('data in getShops.', data);
      this.shops = data;
    });
  }
  selectShop(shop) {
    this.selectedShop = shop;
    this.getOrders();
  }

  downloadOrders() {
    this.orderService
      .downloadOrders(this.selectedShop?.id)
      .subscribe((data: Blob) => {
        console.log('data in downloadOrders.', data);
        saveAs(data, 'orders.csv');
      });
  }

  filterOrders(term: string) {
    // Handle string fields
    const lowercasedTrimmedTerm = term.toLowerCase().trim();

    // Convert term to number for numeric comparisons, if possible
    let numericTerm: number | null = null;
    if (!isNaN(Number(term))) {
      numericTerm = Number(term);
    }

    this.filteredOrders = this.orders.filter((order) => {
      // Check string fields
      const nameMatch = order.name
        ? order.name.toLowerCase().trim().includes(lowercasedTrimmedTerm)
        : false;
      const descriptionMatch = order.description
        ? order.description.toLowerCase().trim().includes(lowercasedTrimmedTerm)
        : false;
      const addressMatch = order.address
        ? order.address.toLowerCase().trim().includes(lowercasedTrimmedTerm)
        : false;

      // Check numeric fields
      const shopIdMatch =
        numericTerm !== null ? order.shop_id === numericTerm : false;

      return nameMatch || descriptionMatch || shopIdMatch || addressMatch;
    });
  }
  getOrders() {
    this.orderService
      .getAllOrders(this.selectedShop?.id)
      .subscribe((orders) => {
        this.orders = orders;
        this.filteredOrders = orders;
      });
  }
  ngOnInit(): void {
    this.getShops();
    this.getOrders();
    // this.orderService.getOrders().subscribe({
    //   next: x => {
    //     console.log('The next value is: ', x)
    //   },
    //   error: err => {
    //     console.error('An error occurred :', err)
    //   },
    //   complete: () => {
    //     console.log('There are no more action happen.')
    //   }
    // });
  }
}
